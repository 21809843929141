export default [
    {
      path: "/home",
      component: () => import("@/pages/Home"),
      // show用于判断是否展示footer
      meta: { show: false },
    },
    {
      path: "/works",
      component: () => import("@/pages/Works"),
      meta: { show: true }
    },
    {
      path: "/news",
      component: () => import("@/pages/News"),
      meta: { show: false },

    },
    {
      path: "/detail",
      component: () => import("@/pages/Detail"),
      meta: { show: true },
    },
    {
      path: "/case",
      component: () => import("@/pages/Case"),
      meta: { show: true },
    },
    {
      path: "/contact",
      component: () => import("@/pages/Contact"),
      meta: { show: false },
    },
    {
      path: "/",
      redirect: "/home",
    },
  ];
  