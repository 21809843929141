<template>
  <div id="app">
    <Header></Header>
    <main class="app-body">
      <!-- 二级路由 -->
      <router-view></router-view>
    </main>
    <Footer :white="$route.meta.show"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: { Header, Footer },
};
</script>

<style>
.app-body {
  min-height: 200px;
  background: #ffffff;
}
</style>
