import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
// 使用插件
Vue.use(VueRouter);
// 重写push和replace方法
let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// 配置路由
const router = new VueRouter({
  mode: 'history',
  routes,
  // 跳转页面返回顶部
  scrollBehavior() {
    return { y: 0 };
  },
});

// router.beforeEach(async (to, from, next) => {
//   let token = store.state.user.token;
//   // 空对象if也会判ture需精确到对象的属性名
//   let userInfo = store.state.user.userInfo.name;
//   // 1. 有token情况
//   if (token) {
//     // 有token要去登录页，需跳转首页
//     if (to.path == "/login") {
//       next("/home");
//     } else {
//       // 去非登录页判断有没有用户信息
//       if (!userInfo) {
//         // 没有用户信息要获取
//         try {
//           // 获取信息成功后放行
//           await store.dispatch("user/getUserInfo");
//           next();
//         } catch (error) {
//           // 获取用户信息失败说明token过期拿不到信息，退出登录并跳登录页
//           store.dispatch("user/userLogout");
//           next("/login");
//         }
//       } else {
//         // 有用户信息放行
//         next();
//       }
//     }
//   } else {
//     // 2. 未登录，trade页, center页, pay页需登录才能访问
//     let toPath = to.path;
//     if (
//       toPath.indexOf("/trade") !== -1 ||
//       toPath.indexOf("/center") !== -1 ||
//       toPath.indexOf("/pay") !== -1
//     ) {
//       // 未登录跳转登录页通过query参数传递要去的地址并在登录页通过query参数判断
//       next("/login?redirect=" + toPath);
//     } else {
//       next();
//     }
//   }
// });
export default router;
