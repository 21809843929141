<template>
  <div class="header">
    <img src="@/assets/home/logo.png" alt="" class="logo" />
    <div class="header-right">
      <router-link to="/home" class="header-item">HOME</router-link>
      <router-link to="/works" class="header-item">WORKS</router-link>
      <router-link to="/news" class="header-item">NEWS</router-link>
      <router-link to="/contact" class="header-item">CONTACT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 43px;
  line-height: 43px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    display: block;
    width: 115px;
    height: 12px;
  }
  .header-right {
    .header-item {
      margin-left: 45px;
      font-size: 7px;
      font-family: Arial;
      font-weight: 400;
      color: #1d1d1d;
    }
  }
}
</style>
