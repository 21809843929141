import request from '@/api/request'
// 获取案例分类
export function getWorkCate() {
    return request({
        url: '/index/get_works_cate',
        method: 'get'
    })
}

// export function getWorks(params) {
//     return request.get('/index/get_works', {params})
// }
// 获取案例分类列表
export function getWorks(params) {
    return request({
        url: '/index/get_works',
        method: 'get',
        params: params
    })
}

// 获取案例详情
export function getWorksInfo(params) {
    return request({
        url: '/index/get_works_info',
        method: 'get',
        params: params
    })
}

// 获取新闻列表
export function getNewsList() {
    return request({
        url: '/index/get_poop',
        method: 'get'
    })
}

// 获取新闻详情
export function getNews(params) {
    return request({
        url: '/index/get_poop_info',
        method: 'get',
        params: params
    })
}

// 获取荣誉列表
export function getHonorList() {
    return request({
        url: '/index/get_honor',
        method: 'get'
    })
}

// 获取招聘列表
export function getRecruitList() {
    return request({
        url: '/index/get_recruit',
        method: 'get'
    })
}

// 获取地址
export function getAddr() {
    return request({
        url: '/index/get_conf',
        method: 'get'
    })
}
