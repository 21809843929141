import Vue from "vue"
import Vuex from 'vuex'
// import home from '@/store/modules/home'
// 使用插件
Vue.use(Vuex)
//对外暴露Store类的一个实例
export default new Vuex.Store({
    modules: {
        // home
    }
})