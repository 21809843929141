<template>
  <div class="footer">
    <div class="footer-content" :style="{ background: white ? '#ffffff' : '' }">
      <div class="home">
        <p class="home-title">WORKS</p>
        <div class="home-list">
          <div
            class="list-item"
            to="/works"
            v-for="(item, i) in cateList"
            :key="item.id"
            @click="goWorks(i)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="home">
        <p class="home-title">NEWS</p>
        <div class="home-list" @click="goNews">
          <div class="list-item" style="cursor: pointer">公司新闻</div>
          <div class="list-item" style="cursor: pointer">荣誉奖项</div>
        </div>
      </div>
      <div class="home">
        <p class="home-title">CONTACT</p>
        <div class="home-list" @click="goContact">
          <div class="list-item" style="cursor: pointer">联系方式</div>
          <div class="list-item" style="cursor: pointer">招贤纳士</div>
        </div>
      </div>
      <div class="home">
        <p class="home-title">CONTACT INFORMATION</p>
        <div class="home-list">
          <span class="addr-item">{{ addrInfo.addr }}</span>
          <span class="addr-item">TEL:{{ addrInfo.tel }}</span>
          <span class="addr-item">MAIL:{{ addrInfo.email }}</span>
        </div>
      </div>
    </div>
    <div class="copyright">
      <img src="@/assets/home/icon.png" alt="" class="copy-logo" />
      <span class="copy-text"
        >COPYRIGHT 2004-2022 无锡市迪赛环境艺术设计事务所 版权所有&nbsp;
      </span>
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        class="copy-text"
        >{{ addrInfo.beian }}</a
      >
    </div>
  </div>
</template>

<script>
import { getWorkCate, getAddr } from "@/api";
export default {
  name: "Footer",
  data() {
    return {
      cateList: [],
      addrInfo: {},
    };
  },
  mounted() {
    this.getCate();
    this.getAddr();
  },
  props: ["white"],
  methods: {
    // 获取案例分类
    async getCate() {
      try {
        const result = await getWorkCate();
        this.cateList = result.list;
      } catch (error) {
        alert(error);
      }
    },
    // 获地址信息
    async getAddr() {
      try {
        const result = await getAddr();
        this.addrInfo = result.info;
      } catch (error) {
        alert(error);
      }
    },
    // 去案例详情
    goWorks(id) {
      this.$router.push({ path: "/works" });
      window.localStorage.setItem("selectedId", id * 1 + 1);
    },
    // 去新闻中心
    goNews() {
      this.$router.push({ path: "/news" });
    },
    // 去联系我们
    goContact() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-content {
  background: #f3f3f3;
  padding: 24px 85px 22px 43px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .home {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .home-title {
      font-size: 10px;
      font-family: Arial;
      font-weight: bold;
      color: #3b3b3b;
      margin-bottom: 17px;
    }
    .home-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .list-item {
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        padding-bottom: 10px;
        &:hover {
          font-weight: bold;
        }
      }
      .addr-item {
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        padding-bottom: 10px;
      }
    }
  }
}
.copyright {
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .copy-logo {
    width: 7px;
    height: 7px;
    margin-right: 3px;
  }
  .copy-text {
    font-size: 7px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #2a2a2a;
  }
}
</style>
