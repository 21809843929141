import axios from "axios"
import nprogress from "nprogress"
// 引入进度条样式
// import "nprogress/nprogress.css" 
// 引入store用于拿取uuid
// import store from '@/store'

// 调用create方法创建axios实例
const request = axios.create({
    // 基础路径
    baseURL: '/api',
    // 超时时间5s
    timeout: 5000
})
if (process.env.NODE_ENV == 'development') {
  request.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {
  request.defaults.baseURL = 'http://adminwxdesy.wxjoi.com/api';
}
// 请求拦截器
// request.interceptors.request.use(config => {
//     if (store.state.user.token) {
//         config.headers.token = store.state.user.token
//     }
//     如果有uuid请求头携带uuid
//     if (store.state.detail.uuid_token) {
//         该请求头属性名是后端协商的
//         config.headers.userTempId = store.state.detail.uuid_token
//     }
//     发起请求开启进度条
//     nprogress.start()
//     return config
// })

// 响应拦截器
request.interceptors.response.use((res) => {
    // 请求成功关闭进度条
    // nprogress.done()
    return res.data
}, (err) => {
    return Promise.reject(err)
})

export default request